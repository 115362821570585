import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { categoryGetAll, priorityGetAll, objectiveAdd, objectiveDelete, objectiveGet, objectiveUpdate } from '../../services/ApiRequestService';
import { TCategory } from '../../types/TCategory';
import { TPriority } from '../../types/TPriority';
import { TObjective } from '../../types/TObjective';
import { convertToDateString } from '../../services/DateService';

const ObjectiveEdit = () => {

    let { id } = useParams();

    if(id === undefined){
        return <p>No objectives</p>;
    }

    const [objective, setObjective] = useState<TObjective>({} as TObjective);
    const [categories, setCategories] = useState<TCategory[]>([]);
    const [priorities, setPriorities] = useState<TPriority[]>([]);

    const [selectedStartDate, setSelectedStartDate] = useState<string>("");
    const [selectedEndDate, setSelectedEndDate] = useState<string>("");

    const navigate = useNavigate();

    
    useEffect(() => {
        if (id === undefined) {
            return;
        }

        //set defaults
        const today = new Date();
        today.setDate(today.getDate());
        var dateString = convertToDateString(today);
        setSelectedStartDate(dateString);
        setSelectedEndDate(dateString);

        if (id !== 'new') {
            objectiveGet(Number(id)).then((objective) => {
                if (objective !== null) {

                    setObjective(objective);

                    setSelectedStartDate(objective.startTime.substring(0, 10));
                    setSelectedEndDate(objective.endTime.substring(0, 10));
                }
            });
        }
        else {
                    //TODO: find better solution
            objective.categoryId = 1;
            objective.priorityId = 1;
        }

        categoryGetAll().then(
            (categories) => {
                setCategories(categories);
            }
        );

        priorityGetAll().then(
            (priorities) => {
                setPriorities(priorities);
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);

    const Save = async () => {

        objective.startTime = selectedStartDate + "T00:00:00Z";
        objective.endTime = selectedEndDate + "T00:00:00Z";

        setObjective(objective);

        if (id === 'new') {
            //insert
            if (id !== undefined) {
                objectiveAdd(objective).then((objective) => {
                    if (objective !== null) {
                        navigate("/objective/" + objective.id);
                    }
                    else {
                        navigate("/objectives");
                    }
                });
            }
        }
        else {
            //update
            if (id !== undefined) {
                objectiveUpdate(objective).then(() => {
                    navigate("/objective/" + id);
                });
            }
        }
    }

    const Delete = async () => {
        if (confirm('Do you want to delete this objective?')) {
            if (id !== undefined) {
                objectiveDelete(Number(id)).then(() => {
                    navigate("/objectives");
                });
            }
        }
    }

    return (
        <React.Fragment>
            <h1>Task</h1>
            <p>Start: <input type="date" value={selectedStartDate} onChange={(e) => setSelectedStartDate(e.target.value)} /></p>
            <p>End: <input type="date" value={selectedEndDate} onChange={(e) => setSelectedEndDate(e.target.value)} /></p>
            <p>Name: <input name="name" type="text" value={objective.name} onChange={(e) => setObjective({ ...objective, name: e.target.value })} /></p>
            {/*<p>Description: <input name="description" type="text" value={objective.description} onChange={(e) => setObjective({ ...objective, description: e.target.value })} /></p>*/}
            <p>Description: <textarea defaultValue={objective.description} onChange={(e) => setObjective({ ...objective, description: e.target.value })}></textarea></p>

            <p>Priority:
                <select value={objective.priorityId} onChange={(e) => setObjective({ ...objective, priorityId: Number(e.target.value) })}>
                    
                    {priorities.map((priority, i) => {
                        return (<option value={priority.id} >{priority.name}</option>)
                    })}
                </select>
            </p>

            <p>Category:
                <select value={objective.categoryId} onChange={(e) => setObjective({ ...objective, categoryId: Number(e.target.value) })}>

                    {categories.map((category, i) => {
                        return (<option value={category.id} >{category.name}</option>)
                    })}
                </select>
            </p>

            <Button type='button' onClick={Save}>Save</Button>
            <Button type='button' onClick={Delete}>Delete</Button>
            <Link to={"/objective/" + id}><Button type='button'>Back</Button></Link>

        </React.Fragment>
    );
};

export default ObjectiveEdit