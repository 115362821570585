import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { sendInviteLink, categoryGet } from '../../services/ApiRequestService';
import { TSendInviteLinkRequest } from '../../types/TSendInviteLinkRequest';
import { TCategory } from '../../types/TCategory';

const Category = () => {
    let { id } = useParams();

    if(id === undefined){
        return (<p>No category</p>);
    }

    const [category, setCategory] = useState<TCategory>({} as TCategory);

    useEffect(() => {
        if (id === undefined) {
            return;
        }

        categoryGet(Number(id)).then((category) => {
            if (category !== null) {
                setCategory(category);
            }
        });
        return () => {
            //alert('Will unmount');
        };
    }, []);

    return (
        <React.Fragment>
            <h1>Category</h1>
            <p>Email: {category.name}</p>
            <Link to={'/category/edit/' + id}><Button type='button'>Edit</Button></Link>
            <Link to='/categories'><Button type='button'>Back</Button></Link>

        </React.Fragment>
    );
};

export default Category