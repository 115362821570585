import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { categoryGet, objectiveGet, priorityGet } from '../../services/ApiRequestService';
import { TCategory } from '../../types/TCategory';
import { TObjective } from '../../types/TObjective';
import { TPriority } from '../../types/TPriority';

const Objective = () => {
    let { id } = useParams();

    if(id === undefined){
        return (<p>No objectives</p>);
    }

    const [objective, setObjective] = useState<TObjective>({} as TObjective);
    const [category, setCategory] = useState<TCategory>({} as TCategory);
    const [priority, setPriority] = useState<TPriority>({} as TPriority);

    useEffect(() => {
        if (id === undefined) {
            return;
        }

        objectiveGet(Number(id)).then((objective) => {
            if (objective !== null) {
                setObjective(objective);

                categoryGet(objective.categoryId).then((category) => {
                    if (category !== null) {
                        setCategory(category);
                    }
                });

                priorityGet(objective.priorityId).then((priority) => {
                    if (priority !== null) {
                        setPriority(priority);
                    }
                });
            }
        });

        
        return () => {
            //alert('Will unmount');
        };
    }, []);

    return (
        <React.Fragment>
            <h1>Task</h1>
            <p>Start: {objective.startTime}</p>
            <p>End: {objective.endTime}</p>
            <p>Name: {objective.name}</p>
            <p>Description: {objective.description}</p>
            <p>Priority: {priority.name}</p>
            <p>Category: {category.name}</p>
            <Link to={'/objective/edit/' + id}><Button type='button'>Edit</Button></Link>
            <Link to='/objectives'><Button type='button'>Back</Button></Link>
        </React.Fragment>
    );
};

export default Objective