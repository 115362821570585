import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { priorityAdd, priorityDelete, priorityGet, priorityUpdate } from '../../services/ApiRequestService';
import { TPriority } from '../../types/TPriority';

const PriorityEdit = () => {

    let { id } = useParams();

    if(id === undefined){
        return <p>No priority</p>;
    }

    const [priority, setPriority] = useState<TPriority>({} as TPriority);

    //TODO: roles checkboxes doesnt work without it. Find better solution or fix it
    const [loaded, setLoaded] = useState<boolean>(false);

    const navigate = useNavigate();

    
    useEffect(() => {
        if (id === undefined) {
            return;
        }

        if (id !== 'new') {
            priorityGet(Number(id)).then((priority) => {
                if (priority !== null) {
                    setPriority(priority);
                    setLoaded(true);
                }
            });
        }
        else {
            setLoaded(true);
        }

        return () => {
            //alert('Will unmount');
        };
    }, []);

    const Save = async () => {

        setPriority(priority);

        if (id === 'new') {
            //insert
            if (id !== undefined) {
                priorityAdd(priority).then((priority) => {
                    if (priority !== null) {
                        navigate("/priority/" + priority.id);
                    }
                    else {
                        navigate("/priorities");
                    }
                });
            }
        }
        else {
            //update
            if (id !== undefined) {
                priorityUpdate(priority).then(() => {
                    navigate("/priority/" + id);
                });
            }
        }
    }

    const Delete = async () => {

        if (confirm('Do you want to delete this priority?')) {
            if (id !== undefined) {
                priorityDelete(Number(id)).then(() => {
                    navigate("/admin/priorities");
                });
            }
        }
    }

    return (
        <React.Fragment>
            <h1>Priority</h1>
            <p>Value: <input name="value" type="number" value={priority.number} onChange={(e) => setPriority({ ...priority, number: Number(e.target.value) })} /></p>
            <p>Description: <input name="name" type="text" value={priority.name} onChange={(e) => setPriority({ ...priority, name: e.target.value })} /></p>

            <Button type='button' onClick={Save}>Save</Button>
            <Button type='button' onClick={Delete}>Delete</Button>
            <Link to='/priorities'><Button type='button'>Back</Button></Link>
        </React.Fragment>
    );
};

export default PriorityEdit