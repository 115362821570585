import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Settings from '../pages/settings/SettingsPage';
import SignInPage from '../pages/SignIn/SignInPage';
import SignUpPage from '../pages/authentication/SignUpPage';
import PasswordRecoveryPage from '../pages/authentication/PasswordRecoveryPage';
import PasswordChangePage from '../pages/authentication/PasswordChangePage';
import Categories from '../pages/category/Categories';
import Priorities from '../pages/priority/Priorities';
import Objectives from '../pages/objective/Objectives';
import Category from '../pages/category/Category';
import Objective from '../pages/objective/Objective';
import ObjectiveEdit from '../pages/objective/ObjectiveEdit';
import CategoryEdit from '../pages/category/CategoryEdit';
import Priority from '../pages/priority/Priority';
import PriorityEdit from '../pages/priority/PriorityEdit';


export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public render() {

        return (
            <React.Fragment>
                <NavMenu />
                <Container>

                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/signin' element={<SignInPage />} />
                        <Route path='/signup' element={<SignUpPage />} />
                        <Route path='/settings/*' element={<Settings />} />
                        <Route path='/passwordRecovery' element={<PasswordRecoveryPage />} />
                        <Route path='/passwordchange' element={<PasswordChangePage />} />


                        <Route path='/categories' element={<Categories />} />
                        <Route path='/category/:id' element={<Category />} />
                        <Route path='/category/edit/:id' element={<CategoryEdit />} />

                        <Route path='/priorities' element={<Priorities />} />
                        <Route path='/priority/:id' element={<Priority />} />
                        <Route path='/priority/edit/:id' element={<PriorityEdit />} />

                        <Route path='/objectives' element={<Objectives />} />
                        <Route path='/objective/:id' element={<Objective />} />
                        <Route path='/objective/edit/:id' element={<ObjectiveEdit />} />
                    </Routes>
                </Container>
            </React.Fragment>
        );
    }
}