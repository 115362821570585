import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { categoryAdd, categoryDelete, categoryGet, categoryUpdate } from '../../services/ApiRequestService';
import { TCategory } from '../../types/TCategory';

const CategoryEdit = () => {

    const { id } = useParams();

    if(id === undefined){
        return <p>No category</p>;
    }

    const [category, setCategory] = useState<TCategory>({} as TCategory);

    //TODO: roles checkboxes doesnt work without it. Find better solution or fix it
    const [loaded, setLoaded] = useState<boolean>(false);

    const navigate = useNavigate();

    
    useEffect(() => {
        if (id === undefined) {
            return;
        }

        if (id !== 'new') {
            categoryGet(Number(id)).then((category) => {
                if (category !== null) {
                    setCategory(category);
                    setLoaded(true);
                }
            });
        }
        else {
            setLoaded(true);
        }  

        return () => {
            //alert('Will unmount');
        };
    }, []);

    const Save = async () => {

        setCategory(category);

        if (id === 'new') {
            //insert
            if (id !== undefined) {
                categoryAdd(category).then((category) => {
                    if (category !== null) {
                        navigate("/category/" + category.id);
                    }
                    else {
                        navigate("/categories");
                    }
                });
            }
        }
        else {
            //update
            if (id !== undefined) {
                categoryUpdate(category).then(() => {
                    navigate("/category/" + id);
                });
            }
        }
    }

    const Delete = async () => {

        if (confirm('Do you want to delete this category?')) {
            if (id !== undefined) {
                categoryDelete(Number(id)).then(() => {
                    navigate("/settings/admin/categories");
                });
            }
        }
    }

    return (
        <React.Fragment>
            <h1>Category</h1>
            <p>Email: <input type="text" value={category.name} onChange={(e) => setCategory({ ...category, name: e.target.value })} /></p>

            <Button type='button' onClick={Save}>Save</Button>
            <Button type='button' onClick={Delete}>Delete</Button>
            <Link to='/categories'><Button type='button'>Back</Button></Link>
        </React.Fragment>
    );
};

export default CategoryEdit