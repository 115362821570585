import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { priorityGetAll } from '../../services/ApiRequestService';
import { TPriority } from '../../types/TPriority';

const Priorities = () => {

    const [priorities, setPriorities] = useState<TPriority[]>([]);

    React.useEffect(() => {

        priorityGetAll().then(
            (priorities) => {
                setPriorities(priorities)
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);
        
    


    return (
        <React.Fragment>
            <h1>List of priorities</h1>
            {priorities.map((priority, i) => {
                return (
                    <div className="row">
                        <div className="col">
                            {priority.number}
                        </div>
                        <div className="col">
                            {priority.name} 
                        </div>
                        <div className="col">
                            <Link to={'/priority/' + priority.id}>
                                <Button type='button'>show</Button>
                            </Link>
                        </div>
                        <div className="col">
                            <Link to={'/priority/edit/' + priority.id}>
                                <Button type='button'>edit</Button>
                            </Link>
                        </div>
                    </div>)
            })}
            
            <Link to='/priority/edit/new'>
                <Button type='button'>new priority</Button>
            </Link>
        </React.Fragment>
    );
};

export default Priorities