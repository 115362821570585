import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { categoryGetAll } from '../../services/ApiRequestService';
import { TCategory } from '../../types/TCategory';

const Categories = () => {

    const [categories, setCategories] = useState<TCategory[]>([]);

    React.useEffect(() => {

        categoryGetAll().then(
            (categories) => {
                setCategories(categories)
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);
        
    return (
        <React.Fragment>
            <h1>List of categories</h1>
            {categories.map((category, i) => {
                return (
                    <div className="row">
                        <div className="col">
                            {category.name}
                        </div>
                        <div className="col">
                            <Link to={'/category/' + category.id}>
                                <Button type='button'>show</Button>
                            </Link>
                        </div>
                        <div className="col">
                            <Link to={'/category/edit/' + category.id}>
                                <Button type='button'>edit</Button>
                            </Link>
                        </div>
                    </div>)
            })}
            
            <Link to='/category/edit/new'>
                <Button type='button'>new category</Button>
            </Link>
        </React.Fragment>
    );
};

export default Categories