import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { priorityGet } from '../../services/ApiRequestService';
import { TSendInviteLinkRequest } from '../../types/TSendInviteLinkRequest';
import { TPriority } from '../../types/TPriority';

const Priority = () => {
    let { id } = useParams();

    if(id === undefined){
        return (<p>No priority</p>);
    }

    const [priority, setPriority] = useState<TPriority>({} as TPriority);

    useEffect(() => {
        if (id === undefined) {
            return;
        }

        priorityGet(Number(id)).then((priority) => {
            if (priority !== null) {
                setPriority(priority);
            }
        });
        return () => {
            //alert('Will unmount');
        };
    }, []);

    return (
        <React.Fragment>
            <h1>Priority</h1>
            <p>Value: {priority.number}</p>
            <p>Description: {priority.name}</p>
            <Link to={'/priority/edit/' + id}><Button type='button'>Edit</Button></Link>
            <Link to='/priorities'><Button type='button'>Back</Button></Link>
        </React.Fragment>
    );
};

export default Priority