import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { categoryGetAll, objectiveGetAll, priorityGetAll } from '../../services/ApiRequestService';
import { convertFromDateString } from '../../services/DateService';
import { TCategory } from '../../types/TCategory';
import { TObjective } from '../../types/TObjective';
import { TPriority } from '../../types/TPriority';

const Objectives = () => {

    const [objectives, setObjectives] = useState<TObjective[]>([]);
    const [categories, setCategories] = useState<TCategory[]>([]);
    const [priorities, setPriorities] = useState<TPriority[]>([]);

    React.useEffect(() => {

        objectiveGetAll().then(
            (objectives) => {
                setObjectives(objectives);

                categoryGetAll().then((categories) => {
                    setCategories(categories);
                });

                priorityGetAll().then((priorities) => {
                    setPriorities(priorities);
                });
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);

    return (
        <React.Fragment>
            <h1>List of tasks</h1>
            {objectives.map((objective, i) => {
                let startDate = convertFromDateString(objective.startTime);
                let endDate = convertFromDateString(objective.endTime);

                return (
                    <div className="row">
                        <div className="col-5">
                            {objective.name}
                        </div>
                        <div className="col-3">
                            {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()} 
                        </div>
                        <div className="col">
                            {priorities.find(f => f.id == objective.priorityId)?.name} 
                        </div>
                        <div className="col">
                            {categories.find(f => f.id == objective.categoryId)?.name}
                        </div>
                        <div className="col">
                            <Link to={'/objective/' + objective.id}>
                                <Button type='button'>show</Button>
                            </Link>
                        </div>
                        <div className="col">
                            <Link to={'/objective/edit/' + objective.id}>
                                <Button type='button'>edit</Button>
                            </Link>
                        </div>
                    </div>)
            })}
            
            <Link to='/objective/edit/new'>
                <Button type='button'>new objective</Button>
            </Link>
        </React.Fragment>
    );
};

export default Objectives